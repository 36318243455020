<template>
    <div>
        <img class="example-headImg" src="../assets/image/example/02-6banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">在线安全观察</div>
            <div class="head-explain-content">建造在线安全质量体系</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem"><span class="title">需求场景</span></div>
            <div class="head-list-item"><span class="title">解决方案</span></div>
            <div class="head-list-item"><span class="title">方案收益</span></div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">需求场景</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-title">纸质化文档</div>
                <div class="purchase-content">纸质填报无法随时上传照片证据，历史归档更无法随手拈来</div>
                <div class="purchase-title">流程繁琐</div>
                <div class="purchase-content">填报过程繁琐，流程不统一，跟踪不及时</div>
                <div class="purchase-title">人工统计</div>
                <div class="purchase-content">每月必须有人专门进行报表统计，更不要说做到及时和准确的考核</div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 49rem;">
            <div class="purchase-content-left">
                <div class="purchase-title">解决方案</div>
            </div>
            <div class="purchase-content-right">
                <img class="solution-img" src="../assets/image/example/02-6解决方案.svg"/>
            </div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">方案收益</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-box">
                    <div class="card">
                        <img class="logo" src="../assets/image/example/02-6即时填报.svg"/>
                        <div>
                            <div class="title1">及时填报</div>
                            <div class="title2">发现安全问题能够立即填报，省去手写纸质表单的时间</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../assets/image/example/02-6拍照上报.svg"/>
                        <div>
                            <div class="title1">拍照上报</div>
                            <div class="title2">及时拍照上传图像数据，纸质填报无法及时关联相关证据</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../assets/image/example/02-6流程透明.svg"/>
                        <div>
                            <div class="title1">流程透明</div>
                            <div class="title2">安全观察卡流转过程全程透明可追踪，及时了解流转进度</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../assets/image/example/02-6月报.svg"/>
                        <div>
                            <div class="title1">月报自动生成</div>
                            <div class="title2">根据填报信息每月自动生成报表，节省人工统计时间提高工作效率</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../assets/image/example/02-6分析.svg"/>
                        <div>
                            <div class="title1">分析优化</div>
                            <div class="title2">通过指标分析发现安全隐患，及时预防，促进行动改善</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../assets/image/example/02-6闭环.svg"/>
                        <div>
                            <div class="title1">闭环管理</div>
                            <div class="title2">形成线上闭环管理，实时消息自动提醒，跟踪相关状态</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.purchase-content-left{
    width:33rem;
    text-align: left;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: left;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 106.1rem;
    height: 36.4rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
.purchase-box{
    width: 106rem;
    height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;

    .card{
        width: 34.8rem;
        height: 15rem;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        // justify-content: center;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 3.3rem 0 0 1.2rem;
        }
        .title2{
            width: 25.2rem;
            height: 4.8rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;

            margin: 0.8rem 0 0 1.2rem;
        }
    }
}
</style>
